<template src="./ApplyExternalResearchGroup.html"></template>

<script>
import ArrobaMedellinFooter from "@/components/ArrobaMedellinFooter/ArrobaMedellinFooter";
import ArrobaMedellinAppBarExternal from "@/components/ArrobaMedellinAppBarExternal/ArrobaMedellinAppBarExternal";
import announcementService from "../../services/Announcement";
import categoryService from "../../services/Category";
import { required } from "vuelidate/lib/validators";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import groupAnnouncementServices from "../../services/GroupAnnouncement";
import { putFile } from "@/services/files";

export default {
  name: "ApplyExternalResearchGroup",
  components: {
    ArrobaMedellinFooter,
    ArrobaMedellinAppBarExternal,
    ModalDescriptionFile,
  },
  data() {
    return {
      announcement: {
        announcement: {
          name: "",
          institution: { name: "" },
          description: "",
          category: { name: "" },
          dateInit: "",
          dateFinish: "",
        },
      },
      groupToApply: {
        group: null,
        fileResume: "",
        fileEndorsement: "",
        fileFormulation: "",
      },
      categories: [],
      modalApplyGroup: false,
      showModalImageDescriptionOne: false,
      showModalImageDescriptionTwo: false,
      showModalImageDescriptionThree: false,
      createdGroup: {},
    };
  },
  validations: {
    groupToApply: {
      nameGroup: { required },
      linkGroupCVLAC: { required },
      categoryId: { required },
      nameCoordinator: { required },
      description: { required },
      emailCoordinator: { required },
      institutionName: { required },
      fileResume: { required },
      fileEndorsement: { required },
      fileFormulation: { required },
    },
  },
  created() {
    this.getSpecificAnnouncement();
  },
  methods: {
    async getSpecificAnnouncement() {
      try {
        this.announcement =
          await announcementService.getSpecificAnnouncementExternal(
            this.$route.params.id
          );
        this.categories = await categoryService.getCategories();
      } catch (e) {
        console.error("error", e);
      }
    },
    openModalImageDescriptionPdfOne() {
      this.showModalImageDescriptionOne = true;
    },
    openModalImageDescriptionPdfTwo() {
      this.showModalImageDescriptionTwo = true;
    },
    openModalImageDescriptionPdfThree() {
      this.showModalImageDescriptionThree = true;
    },
    closeModalDescriptionImageOne() {
      this.showModalImageDescriptionOne = false;
    },
    closeModalDescriptionImageTwo() {
      this.showModalImageDescriptionTwo = false;
    },
    closeModalDescriptionImageThree() {
      this.showModalImageDescriptionThree = false;
    },
    uploadFileInfoOne(info) {
      this.groupToApply.descriptionFileResume = info.description;
      this.groupToApply.fileResume = info.file;
      this.showModalImageDescriptionOne = false;
    },
    uploadFileInfoTwo(info) {
      this.groupToApply.descriptionFileEndorsement = info.description;
      this.groupToApply.fileEndorsement = info.file;
      this.showModalImageDescriptionTwo = false;
    },
    uploadFileInfoThree(info) {
      this.groupToApply.descriptionFileFormulation = info.description;
      this.groupToApply.fileFormulation = info.file;
      this.showModalImageDescriptionThree = false;
    },
    showModalApplyGroup() {
      this.modalApplyGroup = true;
    },
    async submitApplication() {
      try {
        this.$store.dispatch("fetchLoading", true);
        const obj = {
          nameGroup: this.groupToApply.nameGroup,
          linkGroupCVLAC: this.groupToApply.linkGroupCVLAC,
          nameCoordinator: this.groupToApply.nameCoordinator,
          emailCoordinator: this.groupToApply.emailCoordinator,
          categoryId: Number(this.groupToApply.categoryId),
          description: this.groupToApply.description,
          institutionName: this.groupToApply.institutionName,
        };
        this.createdGroup =
          await groupAnnouncementServices.createExternalResearchGroup(obj);
        this.$store.dispatch("fetchLoading", true);
        let urlFileOne = null;
        try {
          this.$store.dispatch("fetchLoading", true);
          urlFileOne = await putFile(
            this.groupToApply.fileResume,
            "groupToApply",
            `${this.groupToApply.fileResume.name}`
          );
          this.groupToApply.fileUrlOne = urlFileOne;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo 1",
            position: "is-bottom",
            type: "is-danger",
          });
        }
        let urlFileTwo = null;
        try {
          this.$store.dispatch("fetchLoading", true);
          urlFileTwo = await putFile(
            this.groupToApply.fileEndorsement,
            "groupToApply",
            `${this.groupToApply.fileEndorsement.name}`
          );
          this.groupToApply.fileUrlTwo = urlFileTwo;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo 2",
            position: "is-bottom",
            type: "is-danger",
          });
        }
        let urlFileThree = null;
        try {
          this.$store.dispatch("fetchLoading", true);
          urlFileThree = await putFile(
            this.groupToApply.fileFormulation,
            "groupToApply",
            `${this.groupToApply.fileFormulation.name}`
          );
          this.groupToApply.fileUrlThree = urlFileThree;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo 3",
            position: "is-bottom",
            type: "is-danger",
          });
        }
        const objApply = {
          groupId: this.createdGroup.id,
          announcementId: this.announcement.announcement.id,
          resume: {
            state: false,
            url: this.groupToApply.fileUrlOne,
          },
          endorsement: {
            state: false,
            url: this.groupToApply.fileUrlTwo,
          },
          formulation: {
            state: false,
            url: this.groupToApply.fileUrlThree,
          },
        };
        this.$store.dispatch("fetchLoading", true);
        await groupAnnouncementServices.applyForExternalResearchGroup(objApply);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Grupo postulado correctamente",
          position: "is-top",
          type: "is-primary",
        });
        this.$router.go(-2);
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        this.modalApplyGroup = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./ApplyExternalResearchGroup.scss">

</style>