<template src="./ArrobaMedellinAppBarExternal.html"></template>

<script>
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";

export default {
  name: "arroba-medellin-app-bar-external",
  components: {
    ArrobaMedellinLogo,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped src="./ArrobaMedellinAppBarExternal.scss"></style>
